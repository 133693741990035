.opacity-loader {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: rgba(255,255,255,0.5);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 9999;
}
