@import-normalize;

@font-face {
	font-family: 'Halvar Engschrift';
	src: local('Halvar Engschrift'), url('./assets/fonts/Halvar/Halvar Engschrift/Halvar Engschrift Bold.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'Halvar Mittelschrift';
	src: url('./assets/fonts/Halvar/Halvar Engschrift/Halvar Engschrift Regular.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Halvar Mittelschrift';
	src: url('./assets/fonts/Halvar/Halvar Engschrift/Halvar Engschrift Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}

:root {
	--c-error: #752024;
	--orange: #DBA742;
	--orange-hover: #C3A05B;
	--base-text-color: #262626;
	--f-engschrift: 'Halvar Engschrift', sans-serif;
	--f-mittelschrift: 'Halvar Mittelschrift', sans-serif;
}

body {
	margin: 0;
	color: var(--base-text-color);
	background-color: #FAFAFA;
}

#root {
	min-height: 100vh;
}

.custom-scrollbar {
	scrollbar-width: thin;
	scrollbar-color: #E5E5E5 transparent;
	scrollbar-gutter: stable;
}
.custom-scrollbar::-webkit-scrollbar {
	width: 6px;
}
.custom-scrollbar::-webkit-scrollbar-track {
	background-color: transparent;
}
.custom-scrollbar::-webkit-scrollbar-thumb {
	background-color: #E5E5E5;
	border-radius: 8px;
}
