.loader {
	min-height: inherit;
	display: flex;
	justify-content: center;
	align-items: center;
}

.path {
	animation: rotate 6s linear infinite;
	transform-origin: center;
}

@keyframes rotate {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}
